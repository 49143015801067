// LICENSE_CODE MIT
import assert from 'assert';
import {EventEmitter} from 'events';
import xutil from './util.js';

const RECURSIVE = '.';
const E = new EventEmitter();
export default E;
E.state = {};

let _prev_max, _max, _err_cb, _err_once_cb, _listener_n = 0, inited = 0;
let prev_init = {};
export let init = E.init = (limit = 1000, err_cb, err_once_cb)=>{
  if (inited)
  {
    if (limit!=1000 && limit !== undefined && limit!=prev_init.limit)
      assert(0, 'je_init_limit_err');
    if (err_cb !== undefined && err_cb!=prev_init.err_cb)
      assert(0, 'je_init_err_cb_err');
    if (err_once_cb !== undefined && err_once_cb!=prev_init.err_once_cb)
      assert(0, 'je_init_err_once_cb_err');
    inited++;
    return;
  }
  _prev_max = E.getMaxListeners();
  _max = limit;
  _err_cb = err_cb||console.error;
  _err_once_cb = err_once_cb||console.error;
  E.setMaxListeners(limit);
  prev_init = {limit, err_cb, err_once_cb};
  inited++;
};
E.uninit = ()=>{
  inited--;
  if (inited)
    return;
  E.setMaxListeners(_prev_max);
  if (_listener_n)
    _err_cb('je_leak', _listener_n);
  E.clear();
};

// XXX colin: create list of fn so will not need to create many events
E._on = (path, fn, opt)=>{
  opt = Object.assign({recursive: false, init: true}, opt);
  // XXX: init can happen with undefined must be handled in fn
  // if (opt.init && E.get(path)===undefined)
  //   assert(0, 'must set default or not use init');
  EventEmitter.prototype.on.call(E, path, fn);
  _listener_n++;
  if (opt.recursive)
  {
    EventEmitter.prototype.on.call(E, RECURSIVE+path, fn);
    _listener_n++;
  }
  if (opt.init)
    fn(E.get(path));
  let listener = {path, fn, recursive: opt.recursive};
  if (_listener_n > _max)
    _err_once_cb('too many listeners', _max);
  return listener;
};

export let on = E.on = (_path, fn, opt)=>{
  let paths = _path;
  let listeners = [];
  if (!(_path instanceof Array))
    paths = [_path];
  for (let path of paths)
    listeners.push(E._on(path, fn, opt));
  return listeners;
};

E.once = (path, fn, opt)=>{
  let listener;
  listener = E.on(path, (...args)=>{
    E.off(listener);
    return fn(...args);
  }, Object.assign({init: false}, opt));
  return listener;
};

E._off = listener=>{
  EventEmitter.prototype.removeListener.call(E, listener.path, listener.fn);
  _listener_n--;
  if (listener.recursive)
  {
    EventEmitter.prototype.removeListener.call(E, RECURSIVE+listener.path,
      listener.fn);
    _listener_n--;
  }
};

E.off = listeners=>{
  if (!(listeners instanceof Array))
    return E._off(listeners);
  for (let l of listeners)
    E.off(l);
};

export let get = E.get = path=>xutil.get(E.state, path);

export let set = E.set = (path, curr, opt)=>{
  opt = Object.assign({force_emit: false}, opt);
  if (!opt.force_emit && xutil.get(E.state, path)===curr)
    return;
  // XXX colin: add typeof object compare
  xutil.set(E.state, path, curr);
  let depth = opt.recursive ? Number.POSITIVE_INFINITY : opt.depth||0;
  let _path;
  do
  {
    // XXX: add support for array for path
    _path = path;
    E.emit_path(_path);
    if (depth--<=0)
      return;
    path = path.replace(/\.[^.]+$/u, '');
  } while (_path!=path);
};

E.set_inc = (path, opt)=>{
  E.set(path, (+E.get(path)||0)+1, opt);
};

export let set_push = E.set_push = (path, curr)=>{
  let arr = E.get(path)||[];
  arr.push(curr);
  E.set(path, arr, {force_emit: true});
};

E.delete = path=>E.set(path, undefined);

E.emit_path = path=>{
  E.emit(path, xutil.get(E.state, path));
  path = path.split('.');
  while (path.length>1)
  {
    path.pop();
    let p = path.join('.');
    E.emit(RECURSIVE+p, xutil.get(E.state, p));
  }
};

E.clear = ()=>{
  EventEmitter.prototype.removeAllListeners.call(E);
  E.state = {};
};

E.debug = ()=>{
  window.je = E;
};
